// Styles

// Main styles

@import "./config/default/app.scss";

@import "./icons.scss";


.e-rte-content .e-content h1 {
  font-size: 1.6rem !important;
}

.e-rte-content .e-content h2 {
  font-size: 1.4rem !important;
}

.e-rte-content .e-content h3 {
  font-size: 1.2rem !important;
}


.e-rte-content .e-content h4 {
  font-size: 1.1rem !important;
}


body{
  background: url("../images/bg-main.svg") no-repeat center center fixed;
  background-size: cover;
  background-position: center;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.3; // Adjust the opacity
    z-index: -2; // Keep it behind all other content
  }
}

.content {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    opacity: 0.85; // Adjust the opacity
    z-index: -1; // Keep it behind all other content
  }
}

.cards-modal{
  height: auto !important;
}
