//
// icons.scss
//

.material-symbols-outlined{
  display: inline-block;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: middle;


  font-family: 'Material Symbols Outlined', serif;
  font-weight: normal;
  font-style: normal;
  font-size: inherit;  //  font-size: inherit;
  line-height: 1; //  line-height: inherit;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;


}
