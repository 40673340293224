// 
// tables.scss
//

th {
    font-weight: $table-head-font-weight;
}

//Table centered (Custom)
.table-centered {
    td,th {
        vertical-align: middle !important;
    }
}


// Table 

.table-nowrap {
    th,td {
        white-space: nowrap;
    }
}

// Custom table components (Custom)
.table {
    .table-user {
        img {
            height: 30px;
            width: 30px;
        }
    }
    &.table-bordered {
        tbody {
            border-top: $border-width solid;
            border-top-color: inherit;
        }
    }
}

.action-icon {
    color: #{map-get($grays, "600")};
    font-size: 1.2rem;
    display: inline-block;
    padding: 0 3px;

    &:hover {
        color: #{map-get($grays, "700")};
    }
}

table {
    // Table Hover
    &.table-hover {
        tbody {
            tr {
                &:hover {
                    > * {
                        --ct-table-accent-bg: rgba(4, 17, 173, 0.15);
                    }
                }
            }
        }
    }
    &.table-selectable {
        tbody {
            tr.selected {
                    background-color: rgba(4, 17, 173, 0.15);
            }
        }
    }
    // Table Active 
    tr {
        &.table-active {
            --ct-table-accent-bg: #f3f7f9;
        }
    }
    //  Table Striped
    &.table-striped {
        tbody {
            tr {
                &:nth-of-type(odd) {
                    >* {
                        --ct-table-accent-bg: #f3f7f9;
                    }
                }
                table {
                    &:not(.table-striped) {
                        th, td {
                            --ct-table-accent-bg: transparent;
                        }
                    }
                }
            }
        }
    }
}
