// preloader.scss

#preloader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: inherit;
    z-index: 9999;
    //display: flex;
    //align-items: center;
    //justify-content: center;
    //flex-direction: column;
    min-height: 200px;
    height: 100%;
}

#status {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.spinner {
    margin: 0 auto;
    //font-size: 10px;
    position: relative;
    //text-indent: -9999em;
    //border-top: 5px solid #{map-get($grays, "300")};
    //border-right: 5px solid #{map-get($grays, "300")};
    //border-bottom: 5px solid #{map-get($grays, "300")};
    //border-left: 5px solid $primary;
    //transform: translateZ(0);
    //animation: SpinnerAnimation 1.1s infinite linear;
    width: 65px;
    height: 65px;
    color: $primary;
    background-color: transparent;
    //border-radius: 50%;
}

#message {
    margin-top: 15px; // Space between the spinner and the message
    text-align: center;
    font-size: 17px; // Adjust as needed
    color: $black; // Or any other color that suits your design
}

@keyframes SpinnerAnimation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
