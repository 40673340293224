// 
// pricing.scss
//

.card-pricing {
  position: relative;
  border: 2px solid $primary;
  border-radius: 10px;
  background-color: $white;

  .card-pricing-toggle-button {
    width: 48px;
    height: 48px;
    border: 1px solid gray;
    border-radius: 24px;
    background-color: transparent;
    cursor: pointer;

    &:hover {
      color: darken(#ff004d, 10%);
    }
  }
  .card-pricing-description{
    padding: 10px 0 0 0;
    text-align: justify-all !important;
  }

    .card-pricing-plan-name {
        padding-bottom: 15px;
    }

    .card-pricing-icon {
        font-size: 22px;
        background-color: rgba(#404146,0.1);
        height: 120px;
        display: inline-block;
        width: 120px;
        line-height: 62px;
        border-radius: 50%;
    }

  h2.card-pricing-price {
    border: 2px solid gray;
    border-radius: 5px;

    &:hover {
      border-color: darken(#ff004d, 10%);
    }
  }
    .card-pricing-price {
        padding: 10px 0 10px 0;
        cursor: pointer;

        span {
            font-size: 40%;
            color: $text-muted;
            letter-spacing: 2px;
            text-transform: uppercase;
        }
    }
    .card-pricing-features {
        color: $text-muted;
        list-style: none;
        margin: 0;
        padding: 20px 0 0 0;

        li {
            padding: 10px;
        }
    }
}

.card-pricing-recommended {
    background-color: rgba(7, 0, 55, 0.75);
    color: $white;

    .card-pricing-icon {
        background-color: rgba($white,0.9);
    }
    .card-pricing-features,.card-pricing-price span {
        color: rgba($white,0.7);
    }
}

