//
// buttons.scss
//

// Button shadow

@mixin button-shadow($color) {
  box-shadow: $btn-box-shadow rgba($color, 0.5);
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-shadow($value);
  }
}

.btn {
  .mdi {
    &:before {
      margin-top: -1px;
    }
  }

  i {
    display: inline-block;
  }
}

.btn-gradient {
  background-image: radial-gradient( circle farthest-corner at 10.2% 55.8%,  rgba(252,37,103,1) 0%, rgba(250,38,151,1) 46.2%, rgba(186,8,181,1) 90.1% );
  color: white;
}

.btn-gradient:hover {
  background: linear-gradient(to right, rgba(7, 0, 55, 0.9), rgba(183, 24, 201, 0.9));
}
//
// light button
//
.btn-light,
.btn-white {
  color: #{map-get($grays, "900")};
}

.btn-white {
  border-color: #{map-get($grays, "300")};

  &:hover,
  &:focus {
    background-color: #{map-get($grays, "100")};
    border-color: #{map-get($grays, "100")};
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 $btn-focus-width #{rgba(map-get($grays, "300"), 0.3)};
  }
}

//
// Link buttons
//
// Make a button look and behave like a link
.btn-link {
  font-weight: $font-weight-normal;
  color: $link-color;
  background-color: transparent;

  &:hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
    background-color: transparent;
    border-color: transparent;
  }

  &:focus,
  &.focus {
    text-decoration: $link-hover-decoration;
    border-color: transparent;
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
    pointer-events: none;
  }

  // No need for an active state here
}

//
// Alternate buttons
//
@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}

//
// Button labels
//

.btn-label {
  margin: -0.55rem 0.9rem -0.55rem -0.9rem;
  padding: 0.6rem 0.9rem;
  background-color: #{rgba(map-get($grays, "900"), 0.1)};
}

.btn-label-right {
  margin: (-$btn-padding-y) (-$btn-padding-x) (-$btn-padding-y) ($btn-padding-x);
  padding: ($btn-padding-y) ($btn-padding-x);
  background-color: #{rgba(map-get($grays, "900"), 0.1)};
  display: inline-block;
}

//
// Button Extra Small Size
//

.btn-xs {
  padding: 0.2rem 0.6rem;
  font-size: 0.75rem;
  border-radius: 0.15rem;
}

//
// Soft Buttons
//

/*
.form-control-color:focus {
  outline: none;
  border: 2px solid transparent;
  border-image: linear-gradient(to right, rgba(102, 88, 221, 1), rgba(255, 63, 122, 1)) 1;
  box-shadow: 0 2px 10px rgba(43, 50, 58, 0.5)
}
 */


@mixin button-variant-soft($bg) {
  color: $bg;
  background-color: rgba($bg, 0.18);
  border-color: rgba($bg, 0.12);

  &:hover {
    color: $white;
    background-color: $bg;
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 $btn-focus-width rgba($bg, 0.3);
  }
}

@each $color, $value in $theme-colors {
  .btn-soft-#{$color} {
    @include button-variant-soft($value);
  }
}


.simple-button {
  cursor: pointer;
  border: none;
  outline: none;
  box-shadow: none;
  background: transparent;
  z-index: 50000;
  color: #070037;

  svg {
    color: #070037;
  }
  &:hover {
    color: $pink;
    svg {
      color: $pink;
    }
  }
}






