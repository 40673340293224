// 
// general.scss
//

html {
  position: relative;
  min-height: 100%;
}

body {
  overflow-x: hidden;
}

.quill-container {
  height: 600px;
  width: 100%;
}

.ai-sidebar {
  position: fixed; /* Keeps it anchored in the viewport */
  right: 0;
  padding-bottom: 30px;
  top: calc($topbar-height) !important; /* Dynamic top offset */
  //transform: translate(-50%, -50%);
  max-height: 100vh;
  box-sizing: border-box;
  overflow-y: auto;
  z-index: 1500;
}


.glow {
  animation: glow-animation 2s infinite alternate;
}

.glow-on-hover:hover {
  @extend .glow;
}

@keyframes glow-animation {
  from {
    box-shadow: 0 0 2.5px #fff, 0 0 5px #fff, 0 0 7.5px #fee7bd, 0 0 10px #fee7bd, 0 0 12.5px #fee7bd, 0 0 15px #fee7bd, 0 0 17.5px #fee7bd;
  }
  to {
    box-shadow: 0 0 5px #fff, 0 0 10px #fee7bd, 0 0 15px #fee7bd, 0 0 20px #fee7bd, 0 0 25px #fee7bd, 0 0 30px #fee7bd, 0 0 35px #fee7bd;
  }
}

.floating-opened-button-text {
  top: $topbar-height;
  z-index: 100001;
  transition: width 0.5s ease-in-out;
  right: 700px;
  position: fixed;
}

.floating-opened-button {
  top: $topbar-height+95;
  z-index: 100001;
  transition: width 0.5s ease-in-out;
  right: 600px;
  position: fixed;
}

.floating-simple-button {
  position: fixed;
  right: 28px;
  top:  $topbar-height+95px;
  z-index: 100001;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  width: 50px;
  height: 50px
}

.floating-button {
  position: fixed;
  right: 28px;
  top:  $topbar-height+95px;
  z-index: 100001;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  transition: width 0.5s ease-in-out;
  width: 75px;
  height: 75px
}


.floating-button .text {
  display: inline-block;
  vertical-align: middle;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.floating-button .icon {
  display: inline-block;
  vertical-align: middle;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}


.floating-button:hover .text {
  opacity: 1; /* Make text visible on hover */
  transition: opacity 0.5s ease-in-out;
}

.floating-button:hover .icon {
  opacity: 0; /* Make text visible on hover */
  transition: opacity 0.5s ease-in-out;
}


.floating-button:hover {
  width: 180px; /* adjust based on content */
}

.cursor-pointer{
  cursor: pointer;
}

.gradient-mesh{
  background: radial-gradient(circle at top left, #fcdda4, #ffc0f1);
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: #333;
}

.form-check-gradient {
  display: flex;
  align-items: center;
  font-size: 1.09375rem;
  font-weight: 400 !important;

  input[type='checkbox'] {
    appearance: none; // Hide the default checkbox
    position: absolute;
    opacity: 0;
    z-index: -1; // Prevent interaction with the hidden checkbox
  }

  .gradient-toggle-switch {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 60px;
    height: 30px;
    background: radial-gradient(circle at top left, #f5f5f5, #e0e0e0); // Lighter for inactive state
    border-radius: 30px; // Rounded corners for a pill shape
    cursor: pointer;
    transition: background 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    .toggle-slider {
      position: absolute;
      width: 25px;
      height: 25px;
      background: #fff;
      border-radius: 50%; // Circular handle
      top: 2.5px; // Centered vertically
      left: 2.5px; // Initial position
      transition: transform 0.3s ease;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }
  }

  // Checked state (active)
  input[type='checkbox']:checked + .gradient-toggle-switch {
    background: radial-gradient(circle at bottom right, #ffc0f1, #fcdda4); // Bright gradient for active state
  }

  // Move the slider when checked
  input[type='checkbox']:checked + .gradient-toggle-switch .toggle-slider {
    transform: translateX(30px); // Move the handle to the right
  }

  .gradient-toggle-label {
    margin-left: 10px;
    font-weight: bold; // Make the label stand out
    color: #333;
    cursor: pointer;
  }
}

