//
// forms.scss
//

.form-control,
.form-select {
  box-shadow: $components-shadow-sm;
}


.form-control-color {
  border: 2px solid transparent; /* Set the initial border */
  border-image: linear-gradient(to right, rgba(102, 88, 221, 0.25), rgba(255, 63, 122, 0.25)) 1;
  width: 100%;
  transition: all 0.4s ease-in-out; /* Smooth transition effect */
  cursor: auto !important;
}

.form-control-color:focus {
  outline: none;
  border: 2px solid transparent;
  border-image: linear-gradient(to right, rgba(102, 88, 221, 1), rgba(255, 63, 122, 1)) 1;
  box-shadow: 0 2px 10px rgba(43, 50, 58, 0.5)
}


// Form-control light
.form-control-light {
  background-color: $input-light-bg !important;
  border-color: $input-light-bg !important;
}


// Form elements (Color and Range)
input.form-control[type="color"],
input.form-control[type="range"] {
  min-height: 39px;
}


// Custom select
.custom-select.is-invalid, .form-control.is-invalid, .custom-select:invalid, .form-control:invalid,
.custom-select.is-valid, .form-control.is-valid, .custom-select:valid, .form-control:valid {
  &:focus {
    box-shadow: none !important;
  }
}

// Comment box
.comment-area-box {
  .form-control {
    border-color: #{map-get($grays, "300")};
    border-radius: $input-border-radius $input-border-radius 0 0;
  }

  .comment-area-btn {
    background-color: #{map-get($grays, "100")};
    padding: 10px;
    border: 1px solid #{map-get($grays, "300")};
    border-top: none;
    border-radius: 0 0 $input-border-radius $input-border-radius;
  }
}

// Search box 
.search-bar {

  .form-control {
    padding-left: 40px;
    padding-right: 20px;
    border-radius: 30px;
  }

  span {
    position: absolute;
    z-index: 10;
    font-size: 16px;
    line-height: $input-height;
    left: 13px;
    top: -2px;
    color: $text-muted;
  }
}

// Show Password
.password-eye {
  &:before {
    font-family: feather !important;
    content: "\e86a";
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    vertical-align: middle;
    line-height: 1.2;
    font-size: 16px;
  }
}

.show-password {
  .password-eye {
    &:before {
      content: "\e86e";
    }
  }
}
